import http from '../plugins/axios'

export const getListCurriculumGroup = async (params) => {
  try {
    const response = await http.get(`/curriculum-groups`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailCurriculumGroup = async (curriculumGroupId) => {
  try {
    const response = await http.get(`/curriculum-groups/${curriculumGroupId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addCurriculumGroup = async (payload) => {
  try {
    const response = await http.post(`/curriculum-groups`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateCurriculumGroup = async (curriculumGroupId, payload) => {
  try {
    const response = await http.patch(`/curriculum-groups/${curriculumGroupId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteCurriculumGroup = async (curriculumGroupId) => {
  try {
    const response = await http.delete(`/curriculum-groups/${curriculumGroupId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const assignCurriculumGroupOrder = async (payload) => {
  try {
    const response = await http.patch(`/curriculum-groups/order`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}