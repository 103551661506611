import React, { useState, useEffect } from 'react'
import { AppShell, Box, Text, NavLink, Badge, ScrollArea } from '@mantine/core'
import { useDisclosure, readLocalStorageValue } from '@mantine/hooks'
import { addCurriculumProgress } from '../../../../services/member-curriculum'
import { useDispatch } from 'react-redux'
import { updateRoute } from '../../../../store/dataRoute'
import AppHeader from '../../../ui/AppShell/AppHeader'
import Breadcrumb from '../../../ui/Breadcrumb'
import { IconBookDownload, IconFileCheck, IconLibrary, IconFileInfo, IconVideo, IconBook } from '@tabler/icons-react'
import { useParams } from 'react-router-dom'
import InfoEvent from './InfoEvent'
import UrlEvent from './UrlEvent'
import ModuleEvent from './ModuleEvent'
import VideoEvent from './VideoEvent'
import TaskEvent from './TaskEvent'

const curriculumStatus = {
  open: {
    label: 'Terbuka',
    color: 'blue'
  },
  closed: {
    label: 'Tutup',
    color: 'red'
  },
  done: {
    label: 'Selesai',
    color: 'green'
  }
}

const defaultState = {
  id: null,
  type: ''
}

const LayoutDetailEvent = ({ detailSchedule, curriculum }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const scheduleId = params.schedule_id
  const [openSidebarDesktop, { toggle: toggleDesktop }] = useDisclosure(true)
  const [openSidebarMobile, { toggle: toggleMobile }] = useDisclosure()
  const [sidebarActive, setSidebarActive] = useState('information')
  const [stateContent, setStateContent] = useState(defaultState)
  const [detailTask, setDetailTask] = useState(null)
  const themeValue = readLocalStorageValue({ key: 'lms-app-theme' })
  

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Pelatihan yang diikuti',
      route: '/member/event'
    },
    {
      label: `${detailSchedule.schedule.event.name}`,
      route: null
    },
  ]

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    if (stateContent.type !== 'task') {
      setDetailTask(null)
    }
  }, [stateContent])

  const handleStateContent = (stateId, typeTask) => {
    setStateContent(defaultState)
    const data = {
      id: stateId,
      type: typeTask
    }
    setStateContent(data)
  }

  const handleAddProgress = async (curriculumId) => {
    const payload = {
      status: 'pending',
      userScheduleId: scheduleId,
      curriculumScheduleId: curriculumId
    }
    try {
      await addCurriculumProgress(payload)
      
    } catch (error) {
      console.log(error)
    }
  }

  const mappingSidebarCurriculum = (curriculums) => {
    if (curriculums.length > 0) {

      const mappingTask = (taskCurriculum) => {
        if (taskCurriculum) {
          return (
            <NavLink
              active={taskCurriculum.task.id === sidebarActive}
              py={12}
              leftSection={<IconBook size="1.3rem" stroke={1.5} />}
              label={<Text fz='12' fw='500' lineClamp={2}>{taskCurriculum.task.name}</Text>}
              onClick={() => [setSidebarActive(taskCurriculum.task.id), handleStateContent(taskCurriculum.task.id, 'task'), setDetailTask(taskCurriculum), toggleMobile()]}
            />
          )
        }
      }

      // eslint-disable-next-line array-callback-return
      const remapCurriculumTask = (dataCurriculum) => dataCurriculum.map((val, index) => {
        if (val.curriculum.type === 'url') {
          return (
            <NavLink
              active={val.id === sidebarActive}
              py={14} 
              key={index}
              leftSection={<IconBookDownload size="1.3rem" stroke={1.5} />}
              label={<Text fz='12' fw='500' lineClamp={2}>{val.curriculum.name}</Text>}
              rightSection={<Badge size="xs" radius="sm" variant="filled" color={curriculumStatus[val.status].color}>{curriculumStatus[val.status].label}</Badge>}
              onClick={() => [setSidebarActive(val.id), handleStateContent(val.id, val.curriculum.type),toggleMobile()]}
              disabled={val.status === 'closed'}
            />
          )
        } else if (val.curriculum.type === 'module') {
          return (
            <NavLink
              active={val.id === sidebarActive}
              py={14} 
              key={index}
              leftSection={<IconBookDownload size="1.3rem" stroke={1.5} />}
              label={<Text fz='12' fw='500' lineClamp={2}>{val.curriculum.name}</Text>}
              rightSection={<Badge size="xs" radius="sm" variant="filled" color={curriculumStatus[val.status].color}>{curriculumStatus[val.status].label}</Badge>}
              onClick={() => [setSidebarActive(val.id), handleStateContent(val.id, val.curriculum.type), toggleMobile()]}
              disabled={val.status === 'closed'}
            />
          )
        } else if (val.curriculum.type === 'video') {
          return (
            <NavLink
              active={val.id === sidebarActive}
              py={14} 
              key={index}
              leftSection={<IconVideo size="1.3rem" stroke={1.5} />}
              label={<Text fz='12' fw='500' lineClamp={2}>{val.curriculum.name}</Text>}
              rightSection={<Badge size="xs" radius="sm" variant="filled" color={curriculumStatus[val.status].color}>{curriculumStatus[val.status].label}</Badge>}
              onClick={() => [setSidebarActive(val.id), handleStateContent(val.id, val.curriculum.type), toggleMobile()]}
              disabled={val.status === 'closed'}
            />
          )
        } else if (val.curriculum.type === 'task') {
          return (
            <NavLink
              py={14}
              key={index}
              leftSection={<IconFileCheck size="1.3rem" stroke={1.5} />} 
              label={<Text fz='12' fw='500' lineClamp={2}>{val.curriculum.name}</Text>}
              childrenOffset={0}
              rightSection={<Badge size="xs" radius="sm" variant="filled" color={curriculumStatus[val.status].color}>{curriculumStatus[val.status].label}</Badge>}
              disableRightSectionRotation={true}
              disabled={val.status === 'closed'}
              onClick={() => val.curriculumProgresses.length === 0 ? handleAddProgress(val.id) : ''}
            >
              {mappingTask(val.curriculum)}
            </NavLink>
          )
        }
      })

      // eslint-disable-next-line
      const remapCurriculumGroup = () => curriculums.map((val, index) => {
        return (
          <NavLink
            py={14} 
            key={index}
            leftSection={<IconLibrary size="1.3rem" stroke={1.5} />}
            label={<Text fz='12' fw='500' lineClamp={2}>{val.name}</Text>}
            // onClick={() => setSidebarActive(val.name)}
            variant='filled'
            childrenOffset={0}
          >
            {remapCurriculumTask(val.curriculumSchedules)}
          </NavLink>
        )
      })

      return (
        <Box p='md'>
          <NavLink
            py={14} 
            leftSection={<IconFileInfo size="1.3rem" stroke={1.5} />}
            label={<Text fz='12' fw='500' lineClamp={2}>Informasi</Text>}
            onClick={() => setSidebarActive('information')}
            variant='filled'
            active
          />
          {/* {videoProgram.length > 0 ? recordingMenu() : ''} */}
          {remapCurriculumGroup()}
        </Box>
      )
    } else {
      return (
        <Box ta='center' mt={20}>
          <Text fz={12}>Program ini belum memiliki materi</Text>
        </Box>
      )
    }
  }

  const displayDataEvent = () => {
    if (sidebarActive === 'information') {
      return <InfoEvent detailEvent={detailSchedule} />
    } else {
      if (stateContent.type === 'url') {
        return <UrlEvent detail={stateContent} />
      } else if (stateContent.type === 'module') {
        return <ModuleEvent detail={stateContent} />
      } else if (stateContent.type === 'video') {
        return <VideoEvent detail={stateContent} />
      } else if (stateContent.type === 'task') {
        return <TaskEvent detail={stateContent} dataTask={detailTask} />
      }
    }
  }

  return (
    <AppShell
      transitionDuration={700}
      transitionTimingFunction="ease"
      offsetScrollbars={false}
      header={{ height: 70 }}
      navbar={{
        width: 280,
        breakpoint: 'sm',
        collapsed: { mobile: !openSidebarMobile, desktop: !openSidebarDesktop },
      }}
      padding='md'
    >
      <AppShell.Header>
        <AppHeader mobileToggle={toggleMobile} desktopToggle={toggleDesktop} />
      </AppShell.Header>
      <AppShell.Navbar>
        <AppShell.Section grow component={ScrollArea}>
          {mappingSidebarCurriculum(curriculum)}
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main style={{ backgroundColor: themeValue !== 'dark' ? '#f7f0f0' : '#2e2e2e', borderRadius: '12px' }}>
        <Box my={14} ml={2}>
          <Breadcrumb />
        </Box>
        <Box p={20} style={{ backgroundColor: themeValue !== 'dark' ? '#ffffff' : '#242424', borderRadius: '12px' }}>
          <Box>
            {displayDataEvent()}
          </Box>
        </Box>
      </AppShell.Main>
    </AppShell>
  )
}

export default LayoutDetailEvent