import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../../layout/AuthLayout'
import { Box, Text, Flex, TextInput, Button, Modal, Center, Loader } from '@mantine/core'
import classes from '../../../AdminLayout.module.css'
import { getDetailEvent } from '../../../../../services/event'
import { getListCurriculumGroup, deleteCurriculumGroup } from '../../../../../services/event-curriculum-group'
import TableSkeleton from '../../../../../components/ui/TableSkeleton'
import TableEventCurriculumGroup from '../../../../../components/pages/EventCurriculumGroup/TableEventCurriculumGroup'
import TableOrderEventCurriculumGroup from '../../../../../components/pages/EventCurriculumGroup/TableOrderEventCurriculumGroup'
import FormEventCurriculumGroup from '../../../../../components/pages/EventCurriculumGroup/FormEventCurriculumGroup'
import { modalDeleteData } from '../../../../../components/ui/Modals/modalDeleteData'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'
import { updateRoute } from '../../../../../store/dataRoute'

const LoadingData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 1.2}>
      <Loader size='md' type='bars' />
    </Center>
  )
}

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Total Materi',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const labelTableOrder = [
  {
    label: 'Order.',
    width: 50
  },
  {
    label: 'Nama Materi',
    width: 'auto'
  },
  {
    label: 'Total Materi',
    width: 'auto'
  },
]

const CurriculumGroupEvent = () => {
  const navigate = useNavigate()
  const params = useParams()
  const eventId = params.id
  const defaultParameter = {
    search: '',
    event: eventId,
    orderBy: 'order',
    order: 'asc'
  }
  const permissionState = useSelector(state => state.permission.access)
  const [parameter, setParameter] = useState(defaultParameter)
  const [detailEvent, setDetailEvent] = useState(null)
  const [curriculumGroupList, setCurriculumGroupList] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [changeOrder, setChangeOrder] = useState(false)
  const dispatch = useDispatch()

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Pelatihan',
      route: '/event'
    },
    {
      label: 'Klasifikasi Materi',
      route: null
    },
  ]

  const handleGetEventCurriculumGroupList = async () => {
    setLoadingTable(true)
    if (detailEvent === null) {
      setLoading(true)
    }
    try {
      const response = await getListCurriculumGroup(parameter)
      setCurriculumGroupList(response.data)
      if (detailEvent === null) {
        handleGetDetailEvent(eventId)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setChangeOrder(false)
      setLoadingTable(false)
    }
  }

  const handleGetDetailEvent = async (idEvent) => {
    try {
      const response = await getDetailEvent(idEvent)
      if (response) {
        setDetailEvent(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetEventCurriculumGroupList()
    // eslint-disable-next-line
  }, [parameter])

  const handleFilter = (name, val) => {
    setParameter((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    curriculum: (val) => {
      navigate(`/event/${eventId}/curriculum-group/${val.id}`)
    },
    delete: (val) => {
      modalDeleteData('materi pelatihan', val.id, val.name, deleteCurriculumGroup, handleGetEventCurriculumGroupList)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionCurriculum = permissionState['curriculum']
    const createdPermission = permissionCurriculum.find(val => val.alias === 'curriculum.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' onClick={() => setOpenModalForm(true)}>Tambah Klasifikasi</Button>
      )
    }
  }

  const loadData = () => {
    return (
      <Box>
        <Text className={classes.titleLayout} mb={20}>Manajemen Klasifikasi Materi Pelatihan "{detailEvent.name}"</Text>
        <Box>
          <Flex justify='flex-end' mb={40}>
            <Button size='xs' mr={6} onClick={() => setChangeOrder(true)} disabled={curriculumGroupList.length <= 1}>Ubah Urutan Klasifikasi</Button>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Klasifikasi. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loadingTable ? <TableSkeleton total={4} /> : !changeOrder ? <TableEventCurriculumGroup label={labelTable} data={curriculumGroupList} actionMethod={handleAction} /> : <TableOrderEventCurriculumGroup label={labelTableOrder} data={curriculumGroupList} cancelSubmit={() => setChangeOrder(false)} handleReloadList={() => handleGetEventCurriculumGroupList()} /> }
        </Box>
      </Box>
    )
  }

  return (
    <AuthLayout>
      <Box>
        {loading ? <LoadingData /> : loadData()}
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Klasifikasi Materi Pelatihan</Text>} withCloseButton={false}>
        <FormEventCurriculumGroup dataCurriculumGroup={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetEventCurriculumGroupList} />
      </Modal>
    </AuthLayout>
  )
}

export default CurriculumGroupEvent