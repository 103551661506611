import React, { useState } from 'react'
import { Table, rem, Flex, Button, Box } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { IconGripVertical } from '@tabler/icons-react'
import classes from './TableOrderEventCurriculumGroup.module.css'
import { assignCurriculumGroupOrder } from '../../../services/event-curriculum-group'
import { notificationSuccess, notificationError } from '../../ui/Notifications'
import { useParams } from 'react-router-dom'

const TableOrderEventCurriculumGroup = ({ label, data, cancelSubmit, handleReloadList }) => {
  const params = useParams()
  const eventId = params.id
  const [state, handlers] = useListState(data)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const handleSubmitOrderCurriculum = async (data) => {
    setLoadingSubmit(true)
    const payload = {
      eventId,
      curriculumGroupIds: []
    }
    const remapOrderCurriculum = data.map((val) => {
      return val.id
    })
    payload.curriculumGroupIds = remapOrderCurriculum
    try {
      const response = await assignCurriculumGroupOrder(payload)
      if (response.length > 0) {
        notificationSuccess('Perubahan Urutan Klasifikasi Berhasil', 'Anda telah berhasil merubah urutan klasifikasi')
        handleReloadList()
      }
    } catch (error) {
      console.log(error)
      notificationError('Perubahan Urutan Klasifikasi Gagal', 'Anda gagal merubah urutan klasifikasi')
    }
  }

  const items = state.map((item, index) => (
    <Draggable key={item.id} index={index} draggableId={item.id}>
      {(provided) => (
        <Table.Tr className={classes.item} ref={provided.innerRef} {...provided.draggableProps}>
          <Table.Td>
            <div className={classes.dragHandle} {...provided.dragHandleProps}>
              <IconGripVertical style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </div>
          </Table.Td>
          <Table.Td>{item.name}</Table.Td>
          <Table.Td>{item.curriculums.length}</Table.Td>
        </Table.Tr>
      )}
    </Draggable>
  ))

  return (
    <Box>
      <Table.ScrollContainer minWidth={768} type='native'>
        <DragDropContext
          onDragEnd={({ destination, source }) =>
            handlers.reorder({ from: source.index, to: destination.index || 0 })
          }
        >
          <Table horizontalSpacing='xs' verticalSpacing='xs' withTableBorder style={{ fontSize: '12px' }}>
            <Table.Thead>
              <Table.Tr>
                {mappingTableHead(label)}
              </Table.Tr>
            </Table.Thead>
            <Droppable droppableId="dnd-list" direction="vertical">
              {(provided) => (
                <Table.Tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {items}
                  {provided.placeholder}
                </Table.Tbody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </Table.ScrollContainer>
      <Box mt={14}>
        <Flex justify='flex-end' mb={14}>
          <Button color='red.9' size='xs' mr={6} onClick={cancelSubmit}>Batal</Button>
          <Button size='xs' loading={loadingSubmit} onClick={() => handleSubmitOrderCurriculum(state)}>Simpan Perubahan</Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default TableOrderEventCurriculumGroup