import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Textarea } from '@mantine/core'
import { addCurriculumGroup, updateCurriculumGroup } from '../../../services/event-curriculum-group'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { useParams } from 'react-router-dom'

const defaultVal = {
  name: '',
  description: '',
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  }
}

const FormEventCurriculumGroup = ({ dataCurriculumGroup, onCloseModal, reloadList }) => {
  const params = useParams()
  const eventId = params.id
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)

  const submitCurriculumGroup = async (formCurriculumGroup) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    const payload = {
      name: formCurriculumGroup.name,
      description: formCurriculumGroup.description,
      eventId: eventId 
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataCurriculumGroup === null) {
      methodFunction = addCurriculumGroup(payload)
      titleMessageSuccess = 'Tambah Klasifikasi Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan klasifikasi materi baru'
      titleMessageError = 'Gagal Menambahkan Klasifikasi'
    } else {
      methodFunction = updateCurriculumGroup(formCurriculumGroup.id, payload)
      titleMessageSuccess = 'Update Klasifikasi Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate klasifikasi materi'
      titleMessageError = 'Gagal Mengupdate Klasifikasi'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  useEffect(() => {
    if (dataCurriculumGroup !== null) {
      handleSetForm(dataCurriculumGroup)
    }
    // eslint-disable-next-line
  }, [dataCurriculumGroup])

  const handleSetForm = async (dataGroup) => {
    const dataDetail = {
      id: dataGroup.id,
      name: dataGroup.name,
      description: dataGroup.description
    }
    setFormData(dataDetail)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Klasifikasi Materi'
          placeholder='Masukkan nama klasifikasi materi'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={(val) => handleChangeForm('name', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mb='md'>
        <Textarea
          name='description'
          value={formData.description}
          label='Deskripsi'
          placeholder='Masukkan deskripsi'
          error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
          onChange={(val) => handleChangeForm('description', val.target.value)}
          withAsterisk
          size='xs'
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
            <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitCurriculumGroup(formData)}>{dataCurriculumGroup === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormEventCurriculumGroup