import React from 'react'
import { Table, Menu, ActionIcon, Tooltip, Group, HoverCard, Text, Box, List } from '@mantine/core'
import { IconDotsVertical, IconTrash, IconPencil, IconInfoCircle, IconNotebook } from '@tabler/icons-react'
import { useSelector } from 'react-redux' 

const defaultTypeCurriculum = {
  module: 'Modul',
  url: 'URL',
  video: 'Rekaman Video',
  task: 'Tugas'
}

const ActionMenu = ({ handleAction }) => {
  const permissionState = useSelector(state => state.permission.access)

  const permissionUpdate = () => {
    const permissionCurriculum = permissionState['curriculum']
    const updatedPermission = permissionCurriculum.find(val => val.alias === 'curriculum.update')
    if (updatedPermission !== undefined) {
      return (
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={14} />}
          onClick={() => handleAction('edit')}
          fw='600'
          fz={11}
        >
          Edit
        </Menu.Item>
      )
    }
  }

  const permissionEventCurriculum = () => {
    const permissionCurriculum = permissionState['curriculum']
    const curriculumEventCurriculum = permissionCurriculum.find(val => val.alias === 'curriculum.read')
    if (curriculumEventCurriculum !== undefined) {
      return (
        <Menu.Item
          leftSection={<IconNotebook stroke={1.5} size={14} />}
          onClick={() => handleAction('curriculum')}
          fw='600'
          fz={11}
        >
          Isi Materi
        </Menu.Item>
      )
    }
  }

  const permissionDelete = () => {
    const permissionCurriculum = permissionState['curriculum']
    const deletePermission = permissionCurriculum.find(val => val.alias === 'curriculum.delete')
    if (deletePermission !== undefined) {
      return (
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={14} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={11}
        >
          Hapus
        </Menu.Item>
      )
    }
  }

  return (
    <Menu width={150} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={8} pt={6} variant='transparent' size='xs'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {permissionState !== null ? permissionUpdate() : ''}
        {permissionState !== null ? permissionEventCurriculum() : ''}
        {permissionState !== null ? permissionDelete() : ''}
      </Menu.Dropdown>
    </Menu>
  )
}

const TableEventCurriculumGroup = ({ label, data, actionMethod }) => {
  const permissionState = useSelector(state => state.permission.access)

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    const mappingMenu = () => {
      const permissionCurriculum = permissionState['curriculum']
      const updatedPermission = permissionCurriculum.find(val => val.alias === 'curriculum.update')
      const deletedPermission = permissionCurriculum.find(val => val.alias === 'curriculum.delete')
      if (updatedPermission === undefined && deletedPermission === undefined) {
        return (
          <Tooltip label='Anda tidak diizinkan untuk mengakses menu ini'>
            <ActionIcon ml={10} mt={6} variant='transparent' size='xs'>
              <IconInfoCircle stroke={1.5}/>
            </ActionIcon>
          </Tooltip>
        )
      } else {
        return (<ActionMenu handleAction={(type) => actionMethod(val, type)} />)
      }
    }

    const showCurriculum = (value) => {

      const mappingCurriculum = (listCurriculum) => listCurriculum.map((val) => {
        return (
          <List.Item>
            <Group justify="space-between" gap="xs">
              <Text fz={12} lineClamp={1}>{val.name}</Text>
              <Text fz={12} lineClamp={1} tt='capitalize'>({defaultTypeCurriculum[val.type]})</Text>
            </Group>
          </List.Item>
        )
      })
      return (
        <Group>
          <HoverCard width={250} shadow="md" withArrow openDelay={200} closeDelay={400}>
            <HoverCard.Target>
              <Text fz={12}>{value.curriculums.length}</Text>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Box>
                <Text fz={12} fw={600} mb={8}>List Materi</Text>
                <List type="ordered" size="xs">
                  {mappingCurriculum(value.curriculums)}
                </List>
              </Box>
            </HoverCard.Dropdown>
          </HoverCard>
        </Group>
      )
    }

    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.name}</Table.Td>
        <Table.Td style={{ cursor: 'pointer' }}>{val.curriculums.length > 0 ? <Box>{showCurriculum(val)}</Box> : 0}</Table.Td>
        <Table.Td>{permissionState !== null ? mappingMenu() : ''}</Table.Td>
      </Table.Tr>
    )
  })
  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover withTableBorder style={{ fontSize: '12px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableEventCurriculumGroup