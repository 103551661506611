import http from '../plugins/axios'

export const getTaskQuestion = async (taskId, params) => {
  try {
    const response = await http.get(`/in/attempts/${taskId}/task-questions`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getAttemptTask = async (scheduleId, curriculumTaskId) => {
  try {
    const response = await http.get(`/in/attempts?userSchedule=${scheduleId}&curriculum=${curriculumTaskId}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const createStartTaskQuestion = async (payload) => {
  try {
    const response = await http.post(`/in/attempts`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const createTaskAnswer = async (attemptId, payload) => {
  try {
    const response = await http.post(`/in/attempts/${attemptId}/attempt-answers`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateTaskAnswer = async (attemptId, questId, payload) => {
  try {
    const response = await http.patch(`/in/attempts/${attemptId}/attempt-answers/${questId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const submitTaskAnswer = async (attemptId, questId, payload) => {
  try {
    const response = await http.patch(`/in/attempts/${attemptId}/submit`)
    return response.data
  } catch(error) {
    throw error
  }
}

