import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Textarea, Select, Center, Loader } from '@mantine/core'
import { addCurriculum, updateCurriculum, getDetailCurriculum } from '../../../services/event-curriculum'
import { getListModule } from '../../../services/module'
import { getListTask } from '../../../services/task'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { useParams } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'

const LoadingData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 1.5}>
      <Loader size='md' type='bars' />
    </Center>
  )
}

const defaultVal = {
  name: '',
  description: '',
  type: 'url',
  content: null,
  moduleId: null,
  taskId: null,
  showTaskFeedback: 'false'
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  },
  type: {
    isError: false,
    message: ''
  },
  content: {
    isError: false,
    message: ''
  },
  moduleId: {
    isError: false,
    message: ''
  },
  taskId: {
    isError: false,
    message: ''
  },
  showTaskFeedback: {
    isError: false,
    message: ''
  },
}

const defaultTypeCurriculum = [
  {
    label: 'Modul',
    value: 'module'
  },
  {
    label: 'URL',
    value: 'url'
  },
  {
    label: 'Rekaman Video',
    value: 'video'
  },
  {
    label: 'Tugas',
    value: 'task'
  },
]

const defaultFeedbackData = [
  {
    label: 'Tampilkan',
    value: 'true'
  },
  {
    label: 'Jangan Tampilkan',
    value: 'false'
  },
]

const FormEventCurriculum = ({ dataCurriculum, onCloseModal, reloadList }) => {
  const params = useParams()
  const curriculumGroupId = params.curriculum_group_id
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [moduleList, setModuleList] = useState([])
  const [loadData, setLoadData] = useState(false)

  const submitCurriculum = async (formCurriculum) => {
    setLoadingForm(true)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setValidationForm(formValidation)
    const payload = {
      name: formCurriculum.name,
      description: formCurriculum.description,
      type: formCurriculum.type,
      curriculumGroupId: curriculumGroupId
    }
    if (formCurriculum.type ==='url' || formCurriculum.type === 'video') {
      payload.content = formCurriculum.content
      payload.moduleId = null
      delete formData.moduleId
      delete formData.taskId
      delete formData.showTaskFeedback
    } else if (formCurriculum.type === 'module') {
      payload.content = null
      payload.moduleId = formCurriculum.moduleId
      delete formData.content
      delete formData.taskId
      delete formData.showTaskFeedback
    } else {
      payload.taskId = formData.taskId
      payload.showTaskFeedback = formData.showTaskFeedback === 'true' ? true : false
      payload.content = null
      payload.moduleId = null
      delete formData.content
      delete formData.moduleId
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataCurriculum === null) {
      methodFunction = addCurriculum(payload)
      titleMessageSuccess = 'Tambah Materi Pelatihan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan materi pelatihan baru'
      titleMessageError = 'Gagal Menambahkan Materi Pelatihan'
    } else {
      methodFunction = updateCurriculum(formCurriculum.id, payload)
      titleMessageSuccess = 'Update Materi Pelatihan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate materi pelatihan'
      titleMessageError = 'Gagal Mengupdate Materi Pelatihan'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      if (Object.keys(errorMessage) !== undefined) {
        Object.values(errorMessage).forEach((el) => {
          Object.keys(formValidation).forEach((element) => {
            if (el.includes(element)) {
              setValidationForm((old) => ({
                ...old,
                [element]: {
                  ...old?.[element],
                  isError: true,
                  message: el
                }
              }))
            }
          })
        })
      }
      
    }
  }

  const handleModuleList = async (typeTask) => {
    let dataApi = ''
    if (typeTask === 'module') {
      handleChangeForm('moduleId', null)
      handleChangeForm('taskId', null)
      dataApi = getListModule()
    } else {
      handleChangeForm('moduleId', null)
      handleChangeForm('taskId', null)
      dataApi = getListTask()
    }
    try {
      const response = await dataApi
      if (response) {
        const data = response.data
        const remapData = data.map((val) => {
          const map = {
            value: val.id,
            label: val.name
          }
          return map
        })
        setModuleList(remapData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetDetailCurriculum = async (curriculumId) => {
    setLoadData(true)
    try {
      const response  = await getDetailCurriculum(curriculumId)
      if (response) {
        const detail = {
          id: response.id,
          name: response.name,
          description: response.description,
          type: response.type,
          content: response.content,
          moduleId: null,
          taskId: null,
          showTaskFeedback: response.showTaskFeedback === true ? 'true' : 'false'
        }
        setFormData(detail)
        if (response.type === 'task') {
          await handleModuleList(response.type)
          handleChangeForm('taskId', response.task !== null ? response.task.id : null)
        }
        if (response.type === 'module') {
          await handleModuleList(response.type)
          handleChangeForm('moduleId', response.module !== null ? response.module.id : null)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadData(false)
    }
  }

  useEffect(() => {
    if (dataCurriculum !== null) {
      handleGetDetailCurriculum(dataCurriculum.id)
    } else {
      setLoadData(false)
    }
    // eslint-disable-next-line
  }, [dataCurriculum])

  useEffect(() => {
    if (formData.type === 'module' || formData.type === 'task') {
      handleModuleList(formData.type)
    }
    //eslint-disable-next-line
  }, [formData.type])

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const mappingFormCurriculum = (type) => {
    if (type === 'url' || type === 'video') {
      return (
        <Box mb='md'>
          <TextInput
            name='content'
            value={formData.content}
            label='Alamat URL'
            placeholder='Masukkan URL'
            error={validationForm.content.isError ? `${validationForm.content.message}` : ''}
            onChange={(val) => handleChangeForm('content', val.target.value)}
            withAsterisk
            size='xs'
          />
        </Box>
      )
    } else if (type === 'module') {
      return (
        <Box mb='md'>
          <Select
            name='moduleId'
            value={formData.moduleId}
            label='Modul Materi' 
            data={moduleList}
            error={validationForm.moduleId.isError ? `${validationForm.moduleId.message}` : ''}
            onChange={(val) => handleChangeForm('moduleId', val)}
            withAsterisk
            searchable
            size='xs'
          />
        </Box>
      )
    } else {
      return (
        <Box>
          <Box mb='md'>
            <Select
              name='taskId'
              value={formData.taskId}
              label='Modul Soal'
              data={moduleList}
              error={validationForm.taskId.isError ? `${validationForm.taskId.message}` : ''}
              onChange={(val) => handleChangeForm('taskId', val)}
              withAsterisk
              size='xs'
              searchable
              allowDeselect={false}
            />
          </Box>
          <Box mb='md'>
            <Select
              name='showTaskFeedback'
              value={formData.showTaskFeedback}
              label='Umpan Balik Soal' 
              data={defaultFeedbackData}
              error={validationForm.showTaskFeedback.isError ? `${validationForm.showTaskFeedback.message}` : ''}
              onChange={(val) => handleChangeForm('showTaskFeedback', val)}
              withAsterisk
              allowDeselect={false}
              size='xs'
            />
          </Box>
        </Box>
        
      )
    }
  }

  const loadForm = () => {
    return (
      <Box>
        <Box mb='md'>
          <TextInput
            name='name'
            value={formData.name}
            label='Nama Materi'
            placeholder='Masukkan nama materi'
            error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
            onChange={(val) => handleChangeForm('name', val.target.value)}
            withAsterisk
            size='xs'
          />
        </Box>
        <Box mb='md'>
          <Textarea
            name='description'
            value={formData.description}
            label='Deskripsi Materi'
            placeholder='Masukkan deskripsi materi'
            error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
            onChange={(val) => handleChangeForm('description', val.target.value)}
            withAsterisk
            size='xs'
          />
        </Box>
        <Box mb='md'>
          <Select
            name='type'
            value={formData.type}
            label='Tipe Materi'
            data={defaultTypeCurriculum}
            error={validationForm.type.isError ? `${validationForm.type.message}` : ''}
            onChange={(val) => handleChangeForm('type', val)}
            withAsterisk
            size='xs'
            allowDeselect={false}
          />
        </Box>
        {mappingFormCurriculum(formData.type)}
        <Box mt={20}>
          <Flex justify='flex-end'>
            <Group>
              <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
              <Button size='xs' loading={loadingForm} variant="filled" onClick={() => submitCurriculum(formData)}>{dataCurriculum === null ? 'Tambah' : 'Update'}</Button>
            </Group>
          </Flex>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {loadData ? <LoadingData /> : loadForm()}
    </Box>
  )
}

export default FormEventCurriculum